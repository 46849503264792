import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {EpmsAppService} from './services/epms-app.service';
import {GlobalAppData} from './models/global-app-data';
import {OrderService} from './services/order.service';
import {Transaction} from './models/transaction';
import {getQueryParamByName, loadExternalJavascript, loadGtmNoscript, updateQueryStringParam} from './common/utils';
import {OscConfigService} from './services/osc-config.service';
import {environment} from '../environments/environment';
import {LoadingService} from './services/loading.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {delay} from 'rxjs/operators';
import {OrderComponent} from './order/order.component';
import {MerchantDetails} from './models/merchant-details';
import {CountryRegionService} from './services/country-region.service';
import { Session } from './models/session';
import { SessionService } from './services/session.service';
import {TransactionStatus} from "./models/transaction-status";
import {SharedService} from "./services/shared.service";
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Constants } from './constants/constants';
import { BreakpointObserver } from '@angular/cdk/layout';

declare const loadGtmScript: Function;

@Component({
  selector: 'app-component',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  paymentType: string = null;
  contact: string = null;
  paymentStatus: string = null;
  orderDetails: Transaction;
  loading = false;
  merchantConfigData: Array<MerchantDetails>;
  @ViewChild(OrderComponent) orderComponent;
  isModeOfReceiptSelectNeeded = false;
  isTransactionValid = true;
  isOscSessionIdValid = true;
  transactionStatus = 'IN_PROGRESS';
  faLeft = faAngleDoubleLeft;
  desktopView = ""
  oscCodes: Array<any> = Constants.OSC_SYSTEM_CODES;

  constructor(private appService: EpmsAppService,
              private odService: OrderService,
              private oscConfigService: OscConfigService,
              private gd: GlobalAppData,
              private _loading: LoadingService,
              private spinner: NgxSpinnerService,
              private countryRegionService: CountryRegionService,
              private sharedService:SharedService,
              private sessionService: SessionService,
              private breakPointResolver : BreakpointObserver) {
  }

  ngOnInit(): void {
    this.breakPointResolver.observe('(min-width : 1100px)')
      .subscribe(result=>{
        if(result.matches){
          this.desktopView = "desktop"
        }
    })

    this.breakPointResolver.observe('(max-width : 1099px)')
      .subscribe(result=>{
        if(result.matches){
          this.desktopView = "mobile"
        }
    })
    this.listenToLoading();
    this.loadGtm();
    const gdSessionData: GlobalAppData = this.appService.getSessionDetails();
    const challengeTxnStatus =  (getQueryParamByName('status') || null);
    if (challengeTxnStatus) {
      this.paymentStatus = challengeTxnStatus.toLocaleLowerCase() === 'approved' ? 'approved' : 'declined';
    }
    if (gdSessionData) {
      updateQueryStringParam('jSession', sessionStorage.getItem('sessionid'));
      this.paymentType = gdSessionData.paymentType;
      this.contact = gdSessionData.address;
      this.orderDetails = gdSessionData.orderTransaction;
      Object.assign(this.gd, gdSessionData);
      
      // Css file loading based on Osc Code
      this.addStyleSheetbyOscCode(this.orderDetails?.originatingSystemCode);

      this.isModeOfReceiptSelectNeeded = false;
      this.countryRegionService.countryList = JSON.parse(sessionStorage.getItem('countries'));
      this.loadPaypalConfig(gdSessionData.oscConfig.programGroup.programGroupName);
      this.changeTofelBGColor(this.gd.oscConfig.oscCode);
      if(this.gd.oscConfig && this.gd.oscConfig.maxRejectAtmptCount) {
        environment.MAX_PAYMENT_ALLOWED_ATTEMPTS = this.gd.oscConfig.maxRejectAtmptCount;
      }
    } else {
      this.gd.attemptCount = sessionStorage.getItem('page') ? Number(sessionStorage.getItem('page')) : 1;
      this.gd.isStubEnabled = this.isStubEnabled();      
      this.loadCountries()
      setTimeout(()=>this.getOrderDetails(),200)
    }
    this.manageSession(getQueryParamByName('jSession'));
  }

  ngOnDestroy(): void {
    this.sessionService.sessionExpiredSubject.unsubscribe();
  }

  isShowPage(){
    if(!this.isModeOfReceiptSelectNeeded && this.isTransactionValid && this.isOscSessionIdValid){
      return true;
    }else {
      return false;
    }
  }

  // OSC based dynamic Styles 
  addStyleSheetbyOscCode(oscCode) {
    var headID = document.getElementsByTagName('head')[0];
    var link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.id = 'widget_styles';
    headID.appendChild(link);
    if(this.oscCodes.includes(oscCode)){
      link.href = './assets/css/ERT.css';
    } else {
      link.href = './assets/css/default.css';
    }
  }

  backToOrder() {
    const cb = function (orderData) {
      if ('VTHOP' === orderData.order.hopIndicator) {
        const url = `${environment.VERTUAL_TERIMINAL_URL}?t=${orderData.orderTransactionId}&status=BACKTOORDER&sourceType=${orderData.sourceType}`;
        location.href = url;
      } else {
        this.orderComponent.merchantResponseComponent.backToOrder('backToOrder', '', {status: orderData.transactionStatus});
      }
    };
    this.processWithNewTxIfRequired(cb.bind(this));
    // this.orderComponent.merchantResponseComponent.backToOrder('backToOrder', '', {});
  }

  processWithNewTxIfRequired(cb) {
    this._loading.setLoading(true, 'false');
    this.odService.getOrder().subscribe({
      next: ord => {
        if (this.transactionStatus !== 'COMPLETE') {
        this.getTxId(ord.transactionStatus, ord.order.attemptCounter).then(txId => {
          updateQueryStringParam('t', txId);
          this.odService.getOrder(txId).subscribe({
            next: orderData => {
              this.gd.orderTransaction = orderData;
              cb(orderData);
            }, error: err => {
              this._loading.setLoading(false, 'false');
            }
          });
        })
      } else{
          this._loading.setLoading(false, 'false');
        }
      }, error: err => {
        this._loading.setLoading(false, 'false');
      }
    })
  }

  getTxId(status: string,attemptCount) {
    let txPromise;
    if ( (status == "DECLINED"||status == "ERROR") && attemptCount < environment.MAX_PAYMENT_ALLOWED_ATTEMPTS){
      txPromise = this.sharedService.getNewTransactionId(this.gd.transId);
    } else {
      txPromise = new Promise(resolve => {
        resolve(this.gd.transId);
      })
    }
    return txPromise;
  }


  private listenToLoading(): void {
    this._loading.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = loading;
        this.spinner.show();
      });
  }

  private changeTofelBGColor(oscCode:string):void{
    if(this.oscCodes.includes(oscCode)){
      document.documentElement.style.setProperty('--tofel-background-color', '#F3F4FD');
    }
  }

  private getOrderDetails(): void {
    this._loading.setLoading(true,'internal');
    this.odService.getOrder().subscribe({next:(result: Transaction) => {
      this._loading.setLoading(false,'internal');
      if (result) {
        this.orderDetails = result;
        this.gd.orderTransaction = result;
        this.gd.sourceType = result.order.sourceType;
        this.gd.subscriptionDetail = result.subscriptionDetail;
        this.changeTofelBGColor(result.originatingSystemCode);
        this.addStyleSheetbyOscCode(result.originatingSystemCode);
        this.checkTransactionStatus();
        if (result.modeOfReceipt !== 'N') {
          this.isModeOfReceiptSelectNeeded = true;
        } else {
          this.loadOscConfigDetails();
        }
      }
    },error: (error: any) => {
      this._loading.setLoading(false,'internal');
    }});
  }

  private checkTransactionStatus() {
    if (this.orderDetails && this.orderDetails.decision
      && this.transactionStatus !== 'IN_PROGRESS'
      && (this.orderDetails.decision.toLocaleLowerCase() === 'accept'
      || this.orderDetails.decision.toLocaleLowerCase() === 'reject'
      || this.orderDetails.decision.toLocaleLowerCase() === 'error'
      || this.orderDetails.decision.toLocaleLowerCase() === 'decline')
    ) {
      this.isTransactionValid = false;
    }
  }

  private setSessionExpiryInterval(milliseconds: number) {
    setInterval(() => {
      const cb = function (orderData) {
        if(this.transactionStatus !== 'COMPLETE') {
          const transactionStatus = new TransactionStatus();
          transactionStatus.orderTransactionId = this.gd.orderTransaction.orderTransactionId;
          transactionStatus.pgmResponseCode = '151';
          transactionStatus.pgmResponseMessage = 'SESSION TIMEOUT';
          const obs = this.odService.updateTransactionStatus(transactionStatus);
          obs.subscribe({
            next: data => {
            }, error: error => {
            }
          });


          this.orderComponent.merchantResponseComponent.redirectToTimeoutURL('', {});
        }
      }
      this.processWithNewTxIfRequired(cb.bind(this));

    }, milliseconds)
  }

private manageSession(sessionId: string): void {
    this.sessionService.get(sessionId)
    .subscribe({next:(session: Session) => {
      this.transactionStatus = session?.transactionStatus;
      if(session.balanceTTLInSeconds > 0) {
        this.sessionService.sessionId = sessionId;
        this.sessionService.startCheckingSessionExpiry = true;
        this.sessionService.sessionExpiredSubject
          .subscribe((isSessionExpired: boolean) => {
            if(isSessionExpired) {
              this.orderComponent.merchantResponseComponent.redirectToTimeoutURL('', {});
            }
          });
        this.setSessionExpiryInterval(session.balanceTTLInSeconds * 1000);
      } else {
        if (this.transactionStatus !== 'COMPLETE') {
          this.orderComponent.merchantResponseComponent.redirectToTimeoutURL('', {});
        }
      }
    },error:error => {
      this.isOscSessionIdValid=false;
    }});
  }

  private loadPaypalConfig(programAppGroupName: string): void {
    const paypalClientId = this.getProgarmBasedPaypalClientId(programAppGroupName);
    if (paypalClientId)
      loadExternalJavascript(environment.PAYPAL_SDK + paypalClientId);
    else
      console.log('Not able to load Paypal ClientId source');
  }

  private loadGtm() {
    try {
      loadGtmScript(environment.GTM_APP_ID);
      loadGtmNoscript(environment.GTM_APP_ID);
    } catch(e) {
      console.error('exception thrown', e);
    }
  }

  private getProgarmBasedPaypalClientId(prgGrpName): string {
    if (prgGrpName === 'ETS PROGRAMS') {
      return environment.PROGRAMS_PAYPAL_CLIENTID;
    } else if (prgGrpName === 'ETS GRE') {
      return environment.GRE_PAYPAL_CLIENTID;
    } else if (prgGrpName === 'ETS TOEFL') {
      return environment.TOEFL_PAYPAL_CLIENTID;
    } else if (prgGrpName === 'ETS PRAXIS') {
      return environment.PRAXIS_PAYPAL_CLIENTID;
    } else {
      return environment.PROGRAMS_PAYPAL_CLIENTID;
    }
  }

  private loadOscConfigDetails(): void {
    const oscConfigObs = this.oscConfigService.getOscConfigForHop(this.orderDetails.originatingSystemCode, this.orderDetails.order.hopIndicator);
    oscConfigObs.subscribe(data => {
      this.gd.oscConfig = data;
      if (!this.gd.isStubEnabled && data && data.programGroup.programGroupName) { // isStub=false
        this.loadPaypalConfig(data.programGroup.programGroupName); // retrieve paypal clientId based on store and program
      }
      if (data && data.maxRejectAtmptCount) {
        environment.MAX_PAYMENT_ALLOWED_ATTEMPTS = data.maxRejectAtmptCount;
      }
      if (data && data.merchantDefinedField) {
        this.merchantConfigData = data.merchantDefinedField;
      }
      this.redirectForOscSessionExists();
    });
  }

  private redirectForOscSessionExists(): void {
    const oscSessionRedirect = getQueryParamByName('oscSessionRedirect');
    console.log(`oscSessionRedirect = ${oscSessionRedirect}`);
    // eslint-disable-next-line eqeqeq
    if (oscSessionRedirect == 'true') {
      this.orderComponent.merchantResponseComponent.redirectToMerchantSuccessURL('osc', {}, 'redirectForOscSessionExists');
    }
  }

  private isStubEnabled(): boolean {
    const isStubParamExists = getQueryParamByName('isStub');
    if (isStubParamExists) {
      return (isStubParamExists === 'true');
    }
    return false;
  }

  private loadCountries(): void {
    const countries = sessionStorage.getItem('countries');
    if (!countries) {
      this.countryRegionService.loadCountries();
    } else {
      this.countryRegionService.countryList = JSON.parse(countries);
    }
  }

  onModeOfReceiptSelect(event): void {
    this.orderDetails.modeOfReceipt = event.target.value;
    this.oscConfigService.updateModeOfReceipt(this.gd.transId, event.target.value).subscribe({next:data => {
      this.isModeOfReceiptSelectNeeded = false;
      this.loadOscConfigDetails();
    }, error:error => {
      console.error(`Error updating the Mode of recipt. Message ${JSON.stringify(error)}`);
      this.isModeOfReceiptSelectNeeded = true;
    }});
  }
}
